import React, { useState } from "react";
import LoginBox from "../../app/Components/Login/LoginBox";
import {Col, Row} from "antd";
import "./LoginPage.css";
import ChangePasswordForm from "../../app/Components/Login/ChangePasswordForm";
import LandingFooter from "../../app/Components/LandingFooter";
import { useIsMobileScreen } from "../../app/Utilities/WindowSize";
import WorkshopCommitmentsWidget from "./WorkshopCommitmentsWidget";

interface IProps {
  defaultBox?: "login" | "register"
}
export const LoginPage: React.FC<IProps> = ({
  defaultBox
}) => {
  const [showLogin, setShowLogin] = useState<boolean>(true);
  const isMobile = useIsMobileScreen(991);

  return (
    <div className="main-container-login-page">
      <div className="login-page-background">
        <div className="content-row-login-page">
          <Row className="min-height-login">
            <Col lg={12} md={24}>
              {
                showLogin ?
                  <LoginBox defaultBox={defaultBox || "login"} goToResetPassword={() => setShowLogin(false)} /> :
                  <ChangePasswordForm goToStart={() => setShowLogin(true)}/>
              }
            </Col>
            <Col lg={12} md={24}>
              {
                !isMobile && <WorkshopCommitmentsWidget />
              }
            </Col>
          </Row>
          <Row>
            {
              !isMobile && (
                <div
                  style={{
                    width: "100%"
                  }}
                >
                  <LandingFooter />
                </div>
              )
            }
          </Row>
        </div>

      </div>
    </div>
  );
};
