import React, { FC, useLayoutEffect, useRef } from "react";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { IBlogContentFragment } from "../../../Blog/interface";

interface IProps {
  fragmentContent: IBlogContentFragment;
}

const TextFragment: FC<IProps> = ({ fragmentContent }) => {
  const ref = useRef<HTMLDivElement>(null);
  const sanitizedHTML = DOMPurify.sanitize(fragmentContent.content);
  const parsedHTML = parse(sanitizedHTML);

  useLayoutEffect(() => {
    const links = (ref.current?.querySelectorAll("a") ||
      []) as HTMLAnchorElement[];
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });
  }, []);

  return <div ref={ref}>{parsedHTML}</div>;
};

export default TextFragment;
