import React, { memo } from "react";
import { Carousel, Col, Row } from "antd";
import { useIsMobileScreen } from "../../../app/Utilities/WindowSize";
import CommitmentItem from "./CommitmentItem";
import "./style.css";

interface IProps {}

const commitments = [
  {
    icon: "hand-shake",
    title: "Transparencia",
    commitmentList: [
      "Honestidad y claridad en los diagnósticos y recomendaciones de servicio.",
      "Obtener consentimiento del usuario antes de cualquier trabajo."
    ]
  },
  {
    icon: "gear",
    title: "Calidad",
    commitmentList: [
      "Utilizar sólo piezas y refacciones de comprobada calidad y de acuerdo con las especificaciones del vehículo.",
      "Respetar la garantía durante un periodo o kilometraje específico, incluido para partes eléctricas y/o electrónicas."
    ]
  },
  {
    icon: "hand-heart",
    title: "Respeto a compromisos",
    commitmentList: [
      "Cumplir los acuerdos con el cliente, como las citas, cotizaciones, horarios de entrega, etc."
    ]
  },
  {
    icon: "hand-ok",
    title: "Precio justo",
    commitmentList: [
      "Precios claros por mano de obra, refacciones y otros servicios.",
      "Respetar las cotizaciones aprobadas vía CarKer por el usuario."
    ]
  },
  {
    icon: "hands",
    title: "Inclusión",
    commitmentList: [
      "Aceptar y respetar a todos los clientes sin diferencias por su género, edad, sexo, preferencias, condición social, religión o cualquier otra razón. ",
    ]
  },
]

const WorkshopCommitmentsWidget: React.FC<IProps> = ({ }: IProps) => {
  const isMobile = useIsMobileScreen(991);

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
      <div style={{
        display: "flex",
        backgroundColor: "#001450",
        height: "70px",
        borderRadius: "10px",
        padding: "10px",
        maxWidth: "650px",
        width: "100%"
      }}>
        <Row>
          <Col span={18}>
            <p style={{fontSize: "16px", color: "#FFF", fontWeight: 600, margin: 0}}>
              Compromisos de los talleres afiliados
            </p>
          </Col>
        </Row>
      </div>
      <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-end"}} className="landing-commit-carousel-container">
        <div style={{position: "relative", height: "300px", width: "100%"}}>
          <div style={{position: "absolute", top: "-20%", right: "1%", width: "100%", height: "100%", display: "flex", justifyContent: "flex-end"}}>
            <Carousel
              className="commitments-landing-carousel"
              infinite
              autoplay
              dots={false}
              //autoplaySpeed={}
            >
              {
                commitments.map((item) => {
                  return (
                    <CommitmentItem icon={item.icon} title={item.title} listItems={item.commitmentList}/>)
                })
              }
            </Carousel>
          </div>
        </div>
      </div>
    </div>

  );
};

export default memo(WorkshopCommitmentsWidget);
