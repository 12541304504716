import React, { FC } from "react";
import { IBlogContentFragment } from "../../../Blog/interface";

interface IProps {
  fragmentContent: IBlogContentFragment;
  sasToken: string;
}

const VideoFragment: FC<IProps> = ({ fragmentContent, sasToken }) => {
  if (fragmentContent.content === "") return null;
  return (
    <div className="embed-responsive embed-responsive-16by9 video-content">
      <video controls>
        <source src={`${fragmentContent.content}?${sasToken}`} />
      </video>
    </div>
  );
};

export default VideoFragment;
