import React, { useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Row,
  message,
  Form,
  Input,
  Modal,
} from "antd";
import { useDispatch } from "react-redux";
import RewardStarIco from "../../assets/Images/reward_star.png";
import ClickHereIco from "../../assets/Images/click_here.png";
import LikeStarsIco from "../../assets/Images/like_starts.png";

import Amazon100 from "../../assets/Images/amazon_100.png";
import Amazon500 from "../../assets/Images/amazon_500.png";

import HeaderRewards from "../../assets/Images/header-carker-rewards.jpg";

import { CloseIcon } from "../../assets/SvgIcons";
import { SendOutlined } from "@ant-design/icons";

import CarkerRewardsPass1 from "../../assets/Images/carker_rewards_pass1.png";
import CarkerRewardsPass2 from "../../assets/Images/carker_rewards_pass2.png";
import CarkerRewardsPass3 from "../../assets/Images/carker_rewards_pass3.png";
import CarkerRewardsPass4 from "../../assets/Images/carker_rewards_pass4.png";
import CarkerRewardsPass5 from "../../assets/Images/carker_rewards_pass5.png";

import BottomArrow from "../../assets/Images/flecha-abajo.png";
import RightArrow from "../../assets/Images/flecha-derecha.png";

import { useWindowSize } from "../../app/Utilities/WindowSize";
import { CurrentUser } from "../../context/UserContext";

import { PromoCodesAPI } from "../../app/Services/axios";
import { RedeemPetition } from "../../../../shared/src/data-models/PromoCodes";
import { Helmet } from "react-helmet";
import { setCurrentSection } from "../../stores";
import { CkMessage, gtmSetId } from "../../CkUI";

interface IProps {}

export const CarkerRewards: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const user = React.useContext(CurrentUser);
  const [totalPoints, setTotalPoints] = useState<number>(0);
  const windowSize = useWindowSize();

  const handleClick = (ev) => {
    gtmSetId(ev.currentTarget)
  }

  React.useEffect(() => {
    dispatch(setCurrentSection("CarKer Rewards"));
    window.scrollTo({ top: 0, behavior: "smooth" });

    const userIdInt = user?.id === undefined ? 0 : parseInt(user?.id);
    if (userIdInt > 0) {
      PromoCodesAPI.getUserBalance(userIdInt)
        .then((userBalance) => {
          setTotalPoints(userBalance.data.totalPoints);
        })
        .catch(() => {
          setTotalPoints(0);
        });
    }
  }, []);

  const redeemPromoCode = (pointsToRedeem: number) => {
    const userIdInt = user?.id === undefined ? 0 : parseInt(user?.id);

    PromoCodesAPI.getUserBalance(userIdInt)
      .then((userBalance) => {
        const availablePoints = userBalance.data.totalPoints;
        if (availablePoints >= pointsToRedeem) {
          setRewardCode(pointsToRedeem, userIdInt);
        } else {
          CkMessage({
            type: "error",
            text: "No tienes los suficientes puntos para poder canjear."
          });
        }
      })
      .catch((err) => {
        CkMessage({
          type: "error",
          text: "No tienes los suficientes puntos para poder canjear."
        });
      });
  };

  const setRewardCode = (pointsToRedeem: number, userId: number) => {
    PromoCodesAPI.getRewards().then((values) => {
      const rewardID = values.data.find(
        (value) => value.points === pointsToRedeem
      )?.sRewardID;

      let redeem: any;
      redeem = {
        UserId: userId,
        RewardId: rewardID,
        Email: user?.email,
      };
      redeemReward(redeem);
    });
  };

  const redeemReward = (redeem: RedeemPetition) => {
    PromoCodesAPI.redeemReward(redeem)
      .then((values) => {
        redeemNotification(values.data.redemptionCode);
      })
      .catch((err) => {
        CkMessage({
          type: "error",
          text: "No tienes los suficientes puntos para poder canjear."
        });
      });
  };

  const redeemNotification = (prizeRedeemedCode: string) => {
    let notificationInfo: any;
    notificationInfo = {
      Email: user?.email,
      PrizeRedeemedCode: prizeRedeemedCode,
    };

    PromoCodesAPI.redeemNotification(notificationInfo).then((values) => {
      CkMessage({
        type: "success",
        text: "¡Tu código de descuento está listo! Recibirás en tu correo mas información."
      });
    });
  };

  const [loading, setLoading] = React.useState<boolean>(false);
  const [invitedEmail, setInvitedEmail] = React.useState<string>();
  const [showPromoSendedInvite, setShowPromoSendedInvite] =
    React.useState<boolean>(false);

  const HandleSendInvitation = ({ email }: any) => {
    setLoading(true);
    setInvitedEmail(email);
    PromoCodesAPI.getInvitationCode({
      userReferrerId: parseInt(user?.id!),
      userInvitedEmail: email,
    })
      .then((result) => {
        if (result) {
          if (result.data !== "") {
            PromoCodesAPI.inviteUser({
              senderUserId: parseInt(user?.id!),
              email,
            })
              .then((result) => {
                if (result.status === 200) {
                  setShowPromoSendedInvite(true);
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 409) {
          CkMessage({
            type: "error",
            text: "Ya existe una invitación a ese correo electrónico.",
          });
        } else {
          CkMessage({
            type: "error",
            text: "Hubo un problema al enviar la invitación, intenta nuevamente",
          });
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        ‍
        <title>
          Recompensas CarKer | Gana Premios y Ayuda a tus Amigos con CarKer
        </title>
        ‍
        <meta
          name="description"
          content="Atrévete a invitar a tus amigos y gana increíbles premios. Participa en CarKer Te Premia y sé miembro activo de nuestra comunidad."
          data-react-helmet="true"
        />
        ‍
      </Helmet>
      <div
        id="carKerRewardsContain"
        className="carKerRewardsContain"
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            paddingTop: 0,
          }}
        >
          <div
            className="commitments-hero-banner"
            style={{
              height:
                windowSize.width >= 1281
                  ? "500px"
                  : windowSize.width >= 450
                  ? "300px"
                  : "125px",
              marginTop: 0,
              paddingTop:
                windowSize.width >= 1281
                  ? "207px"
                  : windowSize.width >= 1201
                  ? "117px"
                  : windowSize.width >= 769
                  ? "117px"
                  : windowSize.width >= 450
                  ? "117px"
                  : "47px",
              backgroundImage: `url(${HeaderRewards})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: windowSize.width >= 450 ? "cover" : "450px 150px",
              backgroundPosition: "top right",
            }}
          >
            <div className={"div-header-left"}>
              <h1 className="white">CarKer™ Rewards</h1>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: windowSize.width < 769 ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px max(2%, 15px)",
            }}
          >
            <img
              style={{
                width: windowSize.width > 991 ? "100px" : "65px",
                height: windowSize.width > 991 ? "100px" : "65px",
                marginRight: "15px",
                //marginTop: windowSize.width > 991 ? "0px" : "10px",
              }}
              src={RewardStarIco}
              alt="RewardStarIco"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: windowSize.width <= 992 ? "100%" : "60%",
                lineHeight: "1.2",
              }}
            >
              <h3 className="blue" style={{ textAlign: "center" }}>
                <b>CarKer™ Rewards</b> es el programa que te premia por invitar
                a tus clientes y amigos a registrarse.
              </h3>
            </div>
          </div>

          {!user ? null : (
            <div
              style={{
                display: "flex",
                flexDirection: windowSize.width >= 850 ? "row" : "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "0 max(2%, 15px) 15px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  border: "solid 1px rgb(47, 102, 242)",
                  borderRadius: "5px",
                  padding: "15px",
                  textAlign: "center",
                }}
              >
                <p className="big blue">Saldo disponible</p>
                <h2 className="black">{totalPoints}</h2>
                <p className="medium black text_centered">Puntos</p>
              </div>
            </div>
          )}
          {!user ? null : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                backgroundColor: "#2f66f2",
              }}
            >
              <div
                style={{
                  width: windowSize.width < 2292 ? "100%" : "2000px",
                  padding: "15px max(2%, 15px)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <img
                    style={{
                      width: windowSize.width > 1280 ? "0px" : "125px",
                      height: windowSize.width > 1280 ? "0px" : "125px",
                      borderRadius: 5,
                    }}
                    src={LikeStarsIco}
                    alt="LikeStarsIco"
                  />
                  <div>
                    <h2 className="medium_landing white">
                      ¡Recomienda CarKer™ y recibe puntos acumulables para
                      realizar compras en Amazon!
                    </h2>
                    <p
                      className="small white"
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      Revisa nuestros{" "}
                      <a
                        href={`${process.env.PUBLIC_URL}/assets/downloads/T&C%20CarKerRewards.pdf`}
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        Términos y condiciones
                      </a>
                      .
                    </p>
                  </div>
                  <img
                    style={{
                      width: windowSize.width > 1280 ? "125px" : "0px",
                      height: windowSize.width > 1280 ? "125px" : "0px",
                      borderRadius: 5,
                      marginLeft: windowSize.width > 1280 ? "5%" : "0px",
                    }}
                    src={LikeStarsIco}
                    alt="LikeStarsIco"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Form
                    onFinish={HandleSendInvitation}
                    className="promo-form"
                    style={{
                      width: windowSize.width > 1920 ? "70%" : "100%",
                      paddingBottom: "0 !important",
                    }}
                    name="promoForm"
                    initialValues={{ remember: true }}
                    onFinishFailed={(failed) => {
                      // console.log(failed);
                    }}
                  >
                    <Row
                      style={{ margin: 0, alignItems: "center" }}
                      gutter={[6, 0]}
                    >
                      <Col lg={20} xs={24}>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Ingresa el correo electrónico",
                            },
                            {
                              type: "email",
                              message: "Debes ingresar un correo electrónico",
                            },
                          ]}
                          name="email"
                          style={{ marginTop: 15, marginBottom: 15 }}
                        >
                          <Input
                            placeholder="Correo electrónico"
                            style={{
                              borderRadius: "6px",
                              height: "49px",
                              marginBottom: 0,
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={4} xs={24}>
                        <Form.Item noStyle>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              size="large"
                              style={{
                                color: "white",
                                backgroundColor: "#f05822",
                                height: "49px",
                                borderRadius: "10px",
                                width:
                                  windowSize.width >= 992
                                    ? "100%"
                                    : windowSize.width >= 350
                                    ? "250px"
                                    : "150px",
                              }}
                              loading={loading}
                              htmlType="submit"
                            >
                              Enviar
                            </Button>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <Modal
                  centered
                  style={{ color: "#454545", textAlign: "center" }}
                  bodyStyle={{
                    backgroundColor: "#FFF",
                    padding: "7%",
                    borderRadius: "10px",
                  }}
                  className="promo-modal"
                  visible={showPromoSendedInvite}
                  width={windowSize.width <= 992 ? "100%" : "578px"}
                  footer={null}
                  onCancel={setShowPromoSendedInvite.bind(null, false)}
                  closeIcon={<CloseIcon style={{ color: "black" }} />}
                  destroyOnClose
                  forceRender
                >
                  <Row gutter={[10, 48]}>
                    <Col span={24}>
                      <SendOutlined
                        style={{
                          fontSize: "4rem",
                          color: "lightgray",
                          marginTop: "1rem",
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <span
                        style={{
                          color: "#0C479D",
                          marginTop: "1rem",
                          marginBottom: "3rem",
                          fontFamily: "Emprint-regular",
                          fontSize: "25px",
                        }}
                      >
                        ¡Invitación enviada!
                      </span>
                    </Col>
                    <Col span={24}>
                      <span
                        style={{
                          fontFamily: "Emprint-regular",
                          fontSize: "20px",
                        }}
                      >
                        Se ha enviado una invitación al correo electrónico
                        <br />
                        <strong style={{ marginTop: "1rem" }}>
                          {invitedEmail}
                        </strong>
                        <br />
                      </span>
                    </Col>
                  </Row>
                </Modal>
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: windowSize.width >= 850 ? "row" : "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className={"amazon-cards"}
                style={{
                  margin: 25,
                  marginBottom: windowSize.width <= 768 ? 0 : 25,
                  display: "flex",
                  flexDirection: "column",
                  width: windowSize.width >= 1280 ? "291px" : "194px",
                  height:
                    windowSize.width >= 1280
                      ? user
                        ? "447px"
                        : windowSize.width >= 1450
                        ? "387px"
                        : "288px"
                      : user
                      ? "298px"
                      : "278px",
                  borderRadius: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignSelf: "flex-start",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    backgroundColor: "#fd9a00",
                    height: "66px",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      marginTop: 20,
                      textAlign: "center",
                      fontFamily: "Emprint-regular",
                      fontSize: windowSize.width >= 1280 ? "24px" : "16px",
                      color: "#ffffff",
                    }}
                  >
                    100 puntos
                  </p>
                </div>
                <img
                  style={{
                    display: "flex",
                    justifySelf: "center",
                    alignSelf: "center",
                    marginTop: windowSize.width >= 1280 ? 25 : 16,
                    width: windowSize.width >= 1450 ? "249px" : "165px",
                    height: windowSize.width >= 1450 ? "235px" : "156px",
                    objectFit: "cover",
                  }}
                  src={Amazon100}
                  alt="Amazon100"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: windowSize.width >= 1280 ? 0 : 10,
                  }}
                >
                  {user ? (
                    <Button
                      className="header-sizing"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#dbdbdb",
                        color: "282828",
                        border: "none",
                        borderRadius: windowSize.width >= 1280 ? 5 : 5,
                        height: windowSize.width >= 1280 ? "44px" : "29px",
                        width: windowSize.width >= 1280 ? "149px" : "99px",
                        lineHeight: "inherit",
                        fontSize: windowSize.width >= 1280 ? "13px" : "9px",
                      }}
                      onClick={() => {
                        redeemPromoCode(100);
                      }}
                    >
                      Canjear premio
                    </Button>
                  ) : null}

                  <a
                    style={{
                      marginTop: 5,
                      fontSize: "13px",
                      color: "#2f66f2",
                      fontFamily: "Emprint-regular",
                      fontStyle: "unset",
                      textDecoration: "underline",
                      marginBottom: "0.8rem",
                    }}
                    href={`${process.env.PUBLIC_URL}/assets/downloads/T&C_Amazon.pdf`}
                    target="_blank"
                    id="REWAR001"
                    onClick={handleClick}
                  >
                    Términos y condiciones
                  </a>
                </div>
              </div>
              <div
                className={"amazon-cards"}
                style={{
                  margin: 25,
                  display: "flex",
                  flexDirection: "column",
                  width: windowSize.width >= 1280 ? "291px" : "194px",
                  height:
                    windowSize.width >= 1280
                      ? user
                        ? "447px"
                        : windowSize.width >= 1450
                        ? "387px"
                        : "288px"
                      : user
                      ? "298px"
                      : "278px",
                  borderRadius: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignSelf: "flex-start",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    backgroundColor: "#010101",
                    height: "66px",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      marginTop: 20,
                      textAlign: "center",
                      fontFamily: "Emprint-regular",
                      fontSize: windowSize.width >= 1280 ? "24px" : "16px",
                      color: "#ffffff",
                    }}
                  >
                    500 puntos
                  </p>
                </div>
                <img
                  style={{
                    display: "flex",
                    justifySelf: "center",
                    alignSelf: "center",
                    marginTop: windowSize.width >= 1280 ? 25 : 16,
                    width: windowSize.width >= 1450 ? "249px" : "165px",
                    height: windowSize.width >= 1450 ? "235px" : "156px",
                    objectFit: "cover",
                  }}
                  src={Amazon500}
                  alt="Amazon500"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: windowSize.width >= 1280 ? 0 : 10,
                  }}
                >
                  {user ? (
                    <Button
                      className="header-sizing"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#dbdbdb",
                        color: "282828",
                        border: "none",
                        borderRadius: windowSize.width >= 1280 ? 5 : 5,
                        height: windowSize.width >= 1280 ? "44px" : "29px",
                        width: windowSize.width >= 1280 ? "149px" : "99px",
                        lineHeight: "inherit",
                        fontSize: windowSize.width >= 1280 ? "13px" : "9px",
                      }}
                      onClick={() => {
                        redeemPromoCode(500);
                      }}
                    >
                      Canjear premio
                    </Button>
                  ) : null}

                  <a
                    style={{
                      marginTop: 5,
                      fontSize: "13px",
                      color: "#2f66f2",
                      fontFamily: "Emprint-regular",
                      fontStyle: "unset",
                      textDecoration: "underline",
                      marginBottom: "0.8rem",
                    }}
                    href={`${process.env.PUBLIC_URL}/assets/downloads/T&C_Amazon.pdf`}
                    target="_blank"
                    id="REWAR002"
                    onClick={handleClick}
                  >
                    Términos y condiciones
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              background: "var(--carker-blue)",
            }}
          >
            <div
              style={{
                padding: "15px max(2%, 15px)",
                width: windowSize.width < 2292 ? "100%" : "2000px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  //alignItems: "center",
                  marginBottom: 15,
                  alignItems: "center",
                  justifyContent: windowSize.width < 576 ? undefined : "center",
                  flexDirection: windowSize.width < 1280 ? "column" : "row",
                }}
              >
                <img
                  style={{
                    width: windowSize.width > 1280 ? "125px" : "110px",
                    height: windowSize.width > 1280 ? "125px" : "110px",
                    borderRadius: 5,
                  }}
                  src={ClickHereIco}
                  alt="ClickHereIco"
                />
                <h2 className="medium_landing white">Solo sigue estos pasos</h2>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  justifyItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: windowSize.width > 1280 ? "row" : "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width:
                        windowSize.width > 1280
                          ? windowSize.width > 1700
                            ? "500px"
                            : "300px"
                          : "300px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: windowSize.width >= 992 ? "200px" : "75px",
                        width: "100%",
                      }}
                    >
                      <img
                        style={{
                          width: windowSize.width > 1280 ? "125px" : "75px",
                          height: windowSize.width > 1280 ? "125px" : "75px",
                          borderRadius: 5,
                        }}
                        src={CarkerRewardsPass1}
                        alt="CarkerRewardsPass1"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        flex: 1,
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          marginTop: 5,
                          color: "white",
                          backgroundColor: "#f05822",
                          borderRadius: 50,
                          width: windowSize.width <= 1280 ? "24px" : "34px",
                          height: windowSize.width <= 1280 ? "24px" : "34px",
                          textAlign: "center",
                          fontSize: windowSize.width <= 1280 ? "24px" : "34px",
                          fontFamily: "Emprint-semibold",
                          lineHeight: 1,
                        }}
                      >
                        1
                      </p>
                      <p
                        className="medium_landing white"
                        style={{
                          marginTop: "-25px",
                          textAlign: "center",
                        }}
                      >
                        <b>Regístrate y recomienda</b> CarKer™ a un amigo.
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: windowSize.width > 1280 ? "75px" : "25px",
                        height: "100%",
                      }}
                      src={windowSize.width > 1280 ? RightArrow : BottomArrow}
                      alt=""
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width:
                        windowSize.width > 1280
                          ? windowSize.width > 1700
                            ? "500px"
                            : "300px"
                          : "300px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: windowSize.width >= 992 ? "200px" : "75px",
                        marginTop: 15,
                        width: "100%",
                      }}
                    >
                      <img
                        style={{
                          width: windowSize.width > 1280 ? "125px" : "75px",
                          height: windowSize.width > 1280 ? "125px" : "75px",
                          borderRadius: 5,
                        }}
                        src={CarkerRewardsPass2}
                        alt="CarkerRewardsPass2"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        flex: 1,
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          marginTop: 5,
                          color: "white",
                          backgroundColor: "#f05822",
                          borderRadius: 50,
                          width: windowSize.width <= 1280 ? "24px" : "34px",
                          height: windowSize.width <= 1280 ? "24px" : "34px",
                          textAlign: "center",
                          fontSize: windowSize.width <= 1280 ? "24px" : "34px",
                          fontFamily: "Emprint-semibold",
                          lineHeight: 1,
                        }}
                      >
                        2
                      </p>
                      <p
                        className="medium_landing white"
                        style={{
                          marginTop: "-25px",
                          textAlign: "center",
                        }}
                      >
                        Espera a que tu amigo <b>se registre como cliente</b> en
                        CarKer™.
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: windowSize.width > 1280 ? "75px" : "25px",
                        height: "100%",
                      }}
                      src={windowSize.width > 1280 ? RightArrow : BottomArrow}
                      alt=""
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width:
                        windowSize.width > 1280
                          ? windowSize.width > 1700
                            ? "500px"
                            : "300px"
                          : "300px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: windowSize.width >= 992 ? "200px" : "75px",
                        marginTop: 15,
                        width: "100%",
                      }}
                    >
                      <img
                        style={{
                          width: windowSize.width > 1280 ? "125px" : "75px",
                          height: windowSize.width > 1280 ? "125px" : "75px",
                          borderRadius: 5,
                        }}
                        src={CarkerRewardsPass3}
                        alt="CarkerRewardsPass3"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        flex: 1,
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          marginTop: 5,
                          color: "white",
                          backgroundColor: "#f05822",
                          borderRadius: 50,
                          width: windowSize.width <= 1280 ? "24px" : "34px",
                          height: windowSize.width <= 1280 ? "24px" : "34px",
                          textAlign: "center",
                          fontSize: windowSize.width <= 1280 ? "24px" : "34px",
                          fontFamily: "Emprint-semibold",
                          lineHeight: 1,
                        }}
                      >
                        3
                      </p>
                      <p
                        className="medium_landing white"
                        style={{
                          marginTop: "-25px",
                          textAlign: "center",
                        }}
                      >
                        ¡Obtén <b>50 puntos</b> cuando complete su primer
                        servicio!
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: windowSize.width > 1280 ? "75px" : "25px",
                        height: "100%",
                      }}
                      src={windowSize.width > 1280 ? RightArrow : BottomArrow}
                      alt=""
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width:
                        windowSize.width > 1280
                          ? windowSize.width > 1700
                            ? "500px"
                            : "300px"
                          : "300px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: windowSize.width >= 992 ? "200px" : "75px",
                        marginTop: 15,
                        width: "100%",
                      }}
                    >
                      <img
                        style={{
                          width: windowSize.width > 1280 ? "125px" : "75px",
                          height: windowSize.width > 1280 ? "125px" : "75px",
                          borderRadius: 5,
                        }}
                        src={CarkerRewardsPass4}
                        alt="CarkerRewardsPass4"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        flex: 1,
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          marginTop: 5,
                          color: "white",
                          backgroundColor: "#f05822",
                          borderRadius: 50,
                          width: windowSize.width <= 1280 ? "24px" : "34px",
                          height: windowSize.width <= 1280 ? "24px" : "34px",
                          textAlign: "center",
                          fontSize: windowSize.width <= 1280 ? "24px" : "34px",
                          fontFamily: "Emprint-semibold",
                          lineHeight: 1,
                        }}
                      >
                        4
                      </p>
                      <p
                        className="medium_landing white"
                        style={{
                          marginTop: "-25px",
                          textAlign: "center",
                        }}
                      >
                        ¡Tu amigo <b>recibirá 100 puntos!</b>
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: windowSize.width > 1280 ? "75px" : "25px",
                        height: "100%",
                      }}
                      src={windowSize.width > 1280 ? RightArrow : BottomArrow}
                      alt=""
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width:
                        windowSize.width > 1280
                          ? windowSize.width > 1700
                            ? "500px"
                            : "300px"
                          : "300px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: windowSize.width >= 992 ? "200px" : "75px",
                        marginTop: 15,
                        width: "100%",
                      }}
                    >
                      <img
                        style={{
                          width: windowSize.width > 1280 ? "125px" : "75px",
                          height: windowSize.width > 1280 ? "125px" : "75px",
                          borderRadius: 5,
                        }}
                        src={CarkerRewardsPass5}
                        alt="CarkerRewardsPass5"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        flex: 1,
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          marginTop: 5,
                          color: "white",
                          backgroundColor: "#f05822",
                          borderRadius: 50,
                          width: windowSize.width <= 1280 ? "24px" : "34px",
                          height: windowSize.width <= 1280 ? "24px" : "34px",
                          textAlign: "center",
                          fontSize: windowSize.width <= 1280 ? "24px" : "34px",
                          fontFamily: "Emprint-semibold",
                          lineHeight: 1,
                        }}
                      >
                        5
                      </p>
                      <p
                        className="medium_landing white"
                        style={{
                          marginTop: "-25px",
                          textAlign: "center",
                          marginBottom: 0,
                        }}
                      >
                        ¡Acumula tus puntos y{" "}
                        <b>utilízalos para tus compras en Amazon</b>!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Row
            style={{
              backgroundColor: "white",
              padding: "15px max(2%, 15px)",
              width: "100%",
            }}
            align="middle"
            justify="center"
          >
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <h2
                  className="orange"
                  style={{
                    paddingTop: 0,
                    marginTop: "10px",
                    marginBottom: windowSize.width <= 992 ? 5 : 10,
                    paddingBottom: windowSize.width <= 992 ? 0 : 12,
                    paddingLeft: 16,
                  }}
                >
                  Preguntas frecuentes
                </h2>
              </div>
            </Col>
            <Col
              span={24}
              style={{ textAlign: "left", backgroundColor: "white" }}
            >
              <Collapse
                bordered={false}
                expandIconPosition="right"
                style={{
                  marginTop: windowSize.width > 992 ? "0px" : "16px",
                  backgroundColor: "white",
                }}
                accordion
              >
                <Divider />
                <Collapse.Panel
                  key={10}
                  header={<span id="REWAR003" onClick={handleClick}>¿A quiénes puedo invitar en este programa?</span>}
                  className="collapse-faq-color"
                >
                  <p>
                    El programa va dirigido a hombres y mujeres mayores de edad
                    que sean propietarios o que usen uno o varios vehículos de
                    forma convencional, y que sean clientes de talleres
                    automotrices de todo tipo. La invitación la puedes extender
                    entonces a quien maneja un auto, una camioneta ligera o una
                    motocicleta.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={1}
                  header={<span id="REWAR004" onClick={handleClick}>¿En qué ciudades pueden vivir mis referidos?</span>}
                  className="collapse-faq-color"
                >
                  <p>
                    Tus referidos pueden vivir en toda la república mexicana, ya
                    que la plataforma se extenderá a todo el país, sin embargo
                    es importante que consideres que en el inicio -marzo 2021-
                    estamos integrando talleres automotrices que se encuentren
                    en tres zonas metropolitanas específicas: Valle de México
                    (es decir, CDMX y municipios conurbados), Toluca y
                    Guadalajara. Los servicios sólo pueden realizarse de inicio
                    en estas tres áreas. Estaremos anunciando progresivamente
                    las zonas geográficas donde CarKer™ comience a tener nuevas
                    coberturas para que tus referidos puedan asistir a talleres
                    en sus localidades.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={2}
                  header={<span id="REWAR005" onClick={handleClick}>¿Qué recibirá mi referido (amigo, familiar, conocido, cliente, etc.), cuando lo invite?</span>}
                  className="collapse-faq-color"
                >
                  <p>
                    Tu amigo, a quien llamamos “referido” va a recibir un correo
                    electrónico donde le explicaremos que hemos recibido sus
                    datos de contacto a través de tí y que únicamente se están
                    empleando para invitarlo a registrarse a esta plataforma
                    (CarKer™), para obtener los beneficios que tú ya estás
                    identificando.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={3}
                  header={<span id="REWAR006" onClick={handleClick}>Invité a un amigo o cliente a CarKer™, pero no he recibido ningún cupón o premio.</span>}
                  className="collapse-faq-color"
                >
                  <p>
                    Dentro de los términos y condiciones del programa explicamos
                    que el premio se recibe hasta que tu amigo realice una
                    reservación en la plataforma, que reciba el servicio
                    completo y realice la evaluación o “rating”. Todo debe
                    registrarse en la plataforma. Cuando se haya completado
                    entonces tanto tú como tu amigo recibirán ambos la
                    acumulación de los puntos correspondientes.
                    <br></br>Si en tu caso recibiste una invitación y has
                    realizado todo el proceso sin recibirlo, entonces te pedimos
                    que te pongas en contacto con el Centro de Servicio a
                    Clientes para verificar por qué no tenemos el registro de tu
                    evento y dar seguimiento.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={4}
                  header={<span id="REWAR007" onClick={handleClick}>Invité a un amigo o cliente a CarKer™, pero me dice que no ha recibido ningún correo.</span>}
                  className="collapse-faq-color"
                >
                  <p>
                    Inicialmente les sugerimos que revisen la bandeja de
                    “correos no deseados”, “promociones” o “trash folder”; en
                    algunas ocasiones la configuración de tu correo envía
                    directamente allá estos correos porque el dominio de
                    carker.com.mx es nuevo para ellos.<br></br>Si has revisado y
                    no se encuentra el folder, te pedimos que verifiques tu
                    correo electrónico, ya que a veces la gente llegamos a
                    cambiar el correo que usamos, o verifiquemos si no lleva una
                    extensión adicional como “.mx”…<br></br>Si ese no es el
                    caso, entonces favor de contactar al Centro de Servicio a
                    Clientes para que ellos nos orienten de cómo darle
                    seguimiento a este referido.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={5}
                  header={<span id="REWAR008" onClick={handleClick}>¿Cómo puedo redimir o canjear mis puntos?</span>}
                  className="collapse-faq-color"
                >
                  <p>
                    Cada premio tiene un valor en puntos, cuando has acumulado
                    el puntaje mínimo entonces puedes seleccionar alguno de
                    ellos dentro de la pantalla de premios. Recibirás un correo
                    electrónico con un cupón digital que podrás usar en la
                    plataforma del proveedor.<br></br>
                    Por ejemplo, si has acumulado 200 puntos, puedes elegir un
                    cupón de Amazon que vale 100 puntos, y recibirás por correo
                    un código QR con valor d $100 pesos, que puedes usar en la
                    compra de los artículos que esta plataforma tiene a la
                    venta.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={6}
                  header={<span id="REWAR009" onClick={handleClick}>¿Yo soy parte de un taller mecánico, puedo recomendar a otras personas?</span>}
                  className="collapse-faq-color"
                >
                  <p>
                    El Programa de Referidos es para todos. En el caso de los
                    usuarios de talleres, Uds. pueden ganar al recomendar a sus
                    clientes que se registren en CarKer™. Todos los referidos
                    reciben la invitación para registrarse como clientes. En su
                    caso sabemos que cuentan con un nutrido grupo de clientes,
                    cuando los refieren al programa reciben 10 puntos por el
                    registro de cada uno de ellos.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={7}
                  header={<span id="REWAR010" onClick={handleClick}>Estoy leyendo que se tiene una vigencia en los puntos, entonces ¿no son permanentes?</span>}
                  className="collapse-faq-color"
                >
                  <p>
                    Así es, el programa está pensado como un recurso de inicio o
                    arranque, en realidad deseamos reconocer y premiar su apoyo
                    al compartirlo con alguien más. Estamos definiendo que los
                    puntos se pueden redimir en ciclos de 6 meses. Una vez que
                    has hecho el canje de tus puntos por un premio, éste (en
                    forma de código digital QR) se mantiene vigente por mucho
                    más tiempo, de acuerdo a las reglas que tiene el proveedor
                    (como por ejemplo, las reglas de Amazon para el uso de sus
                    cupones). El primer ciclo se extiende hasta Septiembre 30 de
                    2021.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={8}
                  header={<span id="REWAR011" onClick={handleClick}>¿Qué pasa si yo quiero recomendar un taller automotriz para que se registre en la plataforma?</span>}
                  className="collapse-faq-color"
                >
                  <p>
                    Si tienes la referencia de un buen taller para integrarlo a
                    CarKer™, tu información es bienvenida. Al momento y basados
                    en la experiencia que tenemos en este canal de servicio,
                    hemos empezado a registrar a los talleres que identificamos
                    en la zona metropolitana del Valle de México, Guadalajara y
                    Toluca. El proceso de registro de un taller lo hacemos
                    físicamente 1 a 1 para garantizar que el sitio cubre con un
                    criterio estricto de calidad en cuanto a servicio, ética,
                    seguridad y capacidad técnica. Por el momento no estamos
                    dando puntos por la recomendación de talleres (solo de
                    clientes convencionales) pero tu aportación es bienvenida al
                    correo del Centro de Servicio a Clientes
                    (soporte@carker.com.mx) para programarnos una visita y
                    evaluar su registro.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={9}
                  header={<span id="REWAR012" onClick={handleClick}>¿Cuáles son los datos del Centro de Servicio a Clientes?</span>}
                  className="collapse-faq-color"
                >
                  <p>
                    Recuerda que si tienes dudas o comentarios te podemos
                    atender personalmente al{" "}
                    <a target="_blank" rel="noopener noreferrer" onClick={handleClick} href="tel:5522820800">55 2282 0800</a>, a través del
                    correo electrónico{" "}
                    <a target="_blank" rel="noopener noreferrer" onClick={handleClick} href="mailto:soporte@carker.com.mx">
                      soporte@carker.com.mx
                    </a>{" "}
                    o en el Whatsapp{" "}
                    <a target="_blank" rel="noopener noreferrer" onClick={handleClick} href="https://wa.me/+525564359958">
                      +52 55 6435 9958
                    </a>
                    .
                  </p>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
