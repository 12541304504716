import React, { useEffect, useState, useRef, FC, memo } from "react";

import { Skeleton } from "antd";

import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { Image } from "antd";
import { IBlogEntry } from "../../../../app/models/blog";
import { CkClockIcon } from "../../../../assets/SvgIcons";
import { CkButton, CkLink } from "../../../../CkUI";
import "./styles.css";

interface IProps {
  isLoading?: boolean;
  type: "big" | "regular" | "small";
  isContent?: boolean;
  item: IBlogEntry;
  sasToken: string;
  tagLabel?: string;
  // key?: string;
}

export const Card: FC<IProps> = memo(
  ({
    isLoading,
    type,
    isContent,
    item,
    sasToken,
    tagLabel,
    // key
  }) => {
    const history = useHistory();

    const descriptionContainerRef = useRef<HTMLDivElement>(null);
    const contentUrl = isLoading
      ? undefined
      : `/blog/${item.category.urlName}/${item.urlTitle}`;

    const setDescriptionHeight = () => {
      if (descriptionContainerRef.current === null) return;

      const coverDescNode = descriptionContainerRef.current.querySelector(
        ".cover-desc"
      ) as HTMLDivElement;
      const descNode = descriptionContainerRef.current.querySelector(
        ".cover-desc .desc"
      ) as HTMLParagraphElement;
      if (!descNode || !coverDescNode) return;

      const containerHeight =
        descriptionContainerRef.current.getBoundingClientRect().height;
      const titleHeight =
        descriptionContainerRef.current
          .querySelector(".cover-title")
          ?.getBoundingClientRect().height || 0;
      const infoHeight =
        descriptionContainerRef.current
          .querySelector(".cover-info")
          ?.getBoundingClientRect().height || 0;
      const linkHeight =
        descriptionContainerRef.current
          .querySelector(".link-container")
          ?.getBoundingClientRect().height || 0;

      coverDescNode.style.height = `${
        containerHeight - titleHeight - infoHeight - linkHeight - 20
      }px`;

      const descContainerHeight = coverDescNode.offsetHeight;
      const lineHeight = parseInt(window.getComputedStyle(descNode).lineHeight);
      const maxLines = Math.floor(descContainerHeight / lineHeight);

      descNode.style.webkitLineClamp = `${maxLines}`;
      descNode.style.height = `${maxLines * lineHeight}px`;
    };

    useEffect(() => {
      !isLoading &&
        descriptionContainerRef.current &&
        type !== "big" &&
        setDescriptionHeight();
    }, [isLoading, item]);

    const CardTag = () => {
      if (isLoading)
        return <Skeleton.Button className="tag-card-skeleton" active={true} />;

      return (
        <div className="tag-card">
          {tagLabel ? (
            <p>{tagLabel}</p>
          ) : (
            <CkButton
              style={{ width: "auto", marginTop: "-2.5px" }}
              // to={{ pathname: `/blog/1?category=${item.category.urlName}` }}
              variant="text"
              color="white"
              onClick={() => {
                history.push(`/blog/1?category=${item.category.urlName}`);
              }}
            >
              {item.category.categoryName}
            </CkButton>
          )}
        </div>
      );
    };

    const CardImage = () => {
      if (isLoading)
        return (
          <Skeleton.Image className="cover-image-skeleton" active={true} />
        );

      return (
        <Image
          className="cover-image"
          preview={false}
          src={`${item.thumbnail}?${sasToken}`}
          alt={item.title}
        />
      );
    };

    const CardTitle = () => {
      if (isLoading) return <Skeleton active={true} />;

      return isContent ? (
        <h1 className="cover-title">{item.title}</h1>
      ) : (
        <span
          className="cover-title"
          // to={{ pathname: contentUrl }}
          // type="link"
          onClick={() => {
            if (contentUrl) history.push(contentUrl);
          }}
        >
          {item.title}
        </span>
      );
    };

    const CardInfo = () => {
      if (isLoading) return null;

      return (
        <div className="cover-info">
          <p className="time">
            <CkClockIcon />
            <span className="web">{item.readingTime} minutos de lectura.</span>
            <span className="mob">{item.readingTime} min.</span>
          </p>

          <p className="autor">{item.author.authorName}</p>
          <p className="date web">
            {moment(item.createdDate).format("D [de] MMMM YYYY")}
          </p>
          <p className="date mob">{moment(item.createdDate).format("L")}</p>
        </div>
      );
    };

    const CardLinkAction = () => {
      if (isLoading) return <Skeleton.Button active={true} />;

      return (
        !isContent && (
          <CkButton
            style={{ width: "auto" }}
            // to={{ pathname: contentUrl }}
            variant="link"
            color="primary"
            onClick={() => {
              if (contentUrl) history.push(contentUrl);
            }}
          >
            Leer más
          </CkButton>
        )
      );
    };

    return (
      <div
        // key={key}
        className={[
          "blog-card",
          ...(type == "big" ? ["big-card-container"] : []),
          ...(type == "regular" ? ["regular-card-container"] : []),
          ...(type == "small" ? ["small-card-container"] : []),
          ...(isContent ? ["is-content"] : []),
        ].join(" ")}
      >
        {isContent && (
          <div className="link-container absolute-link">
            <CkButton
              style={{ width: "auto" }}
              // to={{ pathname: `/blog/1` }}
              variant="link"
              color="primary"
              onClick={() => history.push(`/blog/1`)}
            >
              Regresar al índice
            </CkButton>
          </div>
        )}

        <CardTag />

        <figure className="image-card">
          {isContent && <CardImage />}
          {!isContent && (
            <CkLink
              block={false}
              to={{ pathname: contentUrl }}
              variant="text"
              // onClick={() => {
              //   if (contentUrl) history.push(contentUrl);
              // }}
            >
              <CardImage />
            </CkLink>
          )}
        </figure>

        <div className="force-container" ref={descriptionContainerRef}>
          <CardTitle />

          <CardInfo />

          {!isLoading && (
            <div className="cover-desc">
              <p className="desc">{item.intro}</p>
            </div>
          )}

          {/* <p className="desc">{item.intro}</p>   */}

          {!isContent && (
            <div className="link-container">
              <CardLinkAction />
            </div>
          )}
        </div>
      </div>
    );
  }
);
