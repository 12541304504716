import React, { FC, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Button, Pagination, Spin } from "antd";
import { useHistory, useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card } from "./Card";
import { BlogHeader } from "./BlogHeader";
import { SubscriptionForm } from "./SubscriptionForm";
import { BlogAPI } from "../../../app/Services/BlogAPI";
import { IBlogEntry, IBlogResponse } from "../../../app/models/blog";
import { getSasToken } from "../../../stores";
import { useQuery } from "../../../utilities";
import { CkCaretDownIcon } from "../../../assets/SvgIcons";

import "./styles.css";

const PAGE_SIZE = 5;

interface IProps {}

const loadArray: number[] = [1, 2, 3];

const Blog: FC<IProps> = () => {
  /**
   * States
   */
  const query = useQuery();
  const mainContentRef = useRef<HTMLDivElement>(null);
  const { page } = useParams<{ page: string }>();
  const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
  const [blogEntries, setBlogEntries] = useState<IBlogEntry[] | number[]>([
    ...loadArray,
  ]);
  const [pinnedContents, setPinnedContentes] = useState<IBlogEntry[]>([]);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const sasToken = useSelector(getSasToken);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);

  /**
   * Functions
   */
  const generateNavigationUrl = (page: string | number): string => {
    const params = new URLSearchParams(window.location.search);
    // Get all parameter strings as an array
    const paramStrings = Array.from(params.entries())
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    if (paramStrings) {
      return `/blog/${page}?${paramStrings}`;
    } else {
      return `/blog/${page}`;
    }
  };

  /**
   * get posts
   */
  const getBlogEntries = async () => {
    setLoading(true);
    blogEntries.length > 0 && setBlogEntries([]);
    const category = query.get("category");
    const tag = query.get("tag");
    const author = query.get("author");

    const blogRequest: IBlogResponse = await BlogAPI.getPosts({
      page: parseInt(page),
      pageSize: PAGE_SIZE,
      audience: "WS",
      statusCodes: ["visible"],
      showContent: false,
      ...(category ? { urlCategory: category } : {}),
      ...(tag ? { urlTag: tag } : {}),
      ...(author ? { urlAuthor: author } : {}),
    })
      .then((response) => response.data)
      .catch((e) => {
        console.error("error: ", e);
        return [];
      });
    setDisableNext(blogRequest.entries.length < PAGE_SIZE);
    setBlogEntries(blogRequest.entries);
    setTotalPages(blogRequest.pages);

    if (blogRequest) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  /**
   * get posts
   */
  const getPinnedContents = async () => {
    const pinnedContentsRequest = await BlogAPI.getPosts({
      page: 1,
      pageSize: 4,
      audience: "WS",
      pinnedContent: true,
      statusCodes: ["visible"],
      showContent: false,
    })
      .then((response) => response.data)
      .catch((e) => {
        console.error("error: ", e);
        return undefined;
      });

    setPinnedContentes(pinnedContentsRequest.entries || []);
  };

  const scrollTop = () => {
    mainContentRef.current &&
      mainContentRef.current.scrollIntoView({
        behavior: "smooth",
      });
  };

  /**
   * Effects
   */
  useEffect(() => {
    if (page) getBlogEntries();
  }, [page]);

  useEffect(() => {
    getPinnedContents();
  }, []);

  return (
    <>
      <Helmet>
        <title>CarKer™ | Blog | Conectamos Usuarios y Talleres</title>
        <meta
          name="description"
          content="Somos la primer Startup mexicana que conecta automovilistas con talleres automotrices especializados en la Ciudad de México."
          data-react-helmet="true"
        />
      </Helmet>
      <div className="page-container blog-page">
        <BlogHeader />
        <div className="content-container" ref={mainContentRef}>
          <div className="main-content">
            <Spin className="spin-load" size="large" spinning={loading} />

            {!loading && blogEntries && blogEntries.length == 0 && (
              <div className="no-content">
                <p>¡Ups, no se encontró información!</p>
              </div>
            )}

            {blogEntries && blogEntries.length > 0 && blogEntries[0] && (
              <Card
                isLoading={loading}
                // isLoading={true}
                type="big"
                key={`last-${blogEntries[0].entryid}-post`}
                item={blogEntries[0]}
                sasToken={sasToken}
                {...(parseInt(page) === 1 ? { tagLabel: "Lo último" } : {})}
              />
            )}
            {/* <SubscriptionForm isWeb={false} /> */}
            <div className="card-grid">
              {blogEntries &&
                blogEntries.length > 0 &&
                blogEntries.map((item, index) =>
                  index == 0 ? null : (
                    <Card
                      isLoading={loading}
                      // isLoading={true}
                      key={`${index}-${item.entryid}-post`}
                      type="regular"
                      item={item}
                      sasToken={sasToken}
                    />
                  )
                )}
            </div>
            <div className="pagination-options">
              {!loading && totalPages && (
                <Pagination
                  onChange={(e) => {
                    mainContentRef.current &&
                      mainContentRef.current.scrollIntoView({
                        behavior: "smooth",
                      });

                    history.push(generateNavigationUrl(e));
                  }}
                  defaultCurrent={parseInt(page)}
                  total={totalPages * 10}
                  itemRender={(page, type) => {
                    if (type === "page") return <a rel="nofollow">{page}</a>;
                    if (type === "prev")
                      return (
                        <Button
                          className="prev"
                          aria-label="Página previa"
                          icon={<CkCaretDownIcon />}
                          onClick={scrollTop.bind(this)}
                        />
                      );
                    if (type === "next")
                      return (
                        <Button
                          className="next"
                          aria-label="Página siguiente"
                          icon={<CkCaretDownIcon />}
                          onClick={scrollTop.bind(this)}
                        />
                      );
                    return type;
                  }}
                />
              )}
            </div>
          </div>
          {/* <Divider className="page-divider" dashed type="vertical" /> */}
          <div className="pinned-content">
            {/* <SubscriptionForm isWeb={true} /> */}

            {pinnedContents && pinnedContents.length > 0 && (
              <>
                <p className="pinned-extra">Te puede interesar:</p>
                <div className="pinned-scroll">
                  {pinnedContents.map((item, index) => (
                    <Card
                      type="small"
                      key={`${index}-${item.entryid}`}
                      isLoading={loading}
                      item={item}
                      sasToken={sasToken}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Blog;
