import React, { FC } from "react";
import { getImageBaseUrl } from "../../../../app/Utilities";
const pc = getImageBaseUrl("blog/banner-desktop.jpg");
const pcWebp = getImageBaseUrl("blog/banner-desktop.webp");
const mobile = getImageBaseUrl("blog/banner-mobile.jpg");
const mobileWebp = getImageBaseUrl("blog/banner-mobile.webp");

import "./style.css";

export const BlogHeader: FC<{}> = ({}) => {
  return (
    <figure className="header-container">
      {/*
      <figcaption className="flag">
        <h1>
          Tu BLOG<span>AUTOMOTRIZ</span>
        </h1>
      </figcaption>
      */}
      <picture>
        {/* <!-- sources for viewport width greater than 768px --> */}
        <source
          srcSet={pcWebp}
          type="image/webp"
          media="(min-width: 577px)"
        />
        <source
          srcSet={pc}
          type="image/jpeg"
          media="(min-width: 577px)"
        />
        {/* <!-- sources for viewport width 768px or less --> */}
        <source
          srcSet={mobileWebp}
          type="image/webp"
          media="(max-width: 576px)"
        />
        <source
          srcSet={mobile}
          type="image/jpeg"
          media="(max-width: 576px)"
        />
        {/* <!-- fallback image for browsers that don't support the picture element --> */}
        <img src={mobile} alt={"Tu blog automotriz"} />
      </picture>
    </figure>
  );
};
