import React, { memo } from "react";
import { Row } from "antd";
import { useIsMobileScreen } from "../../../../app/Utilities/WindowSize";
import { CkIcon } from "../../../../CkUI";
import "./style.css";

interface IProps {
  icon: any;
  title: string;
  listItems: string[];
}


const CommitmentItem: React.FC<IProps> = ({ icon, title, listItems }: IProps) => {
  const isMobile = useIsMobileScreen(967);

  return (
    <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "8px", maxWidth: "340px"}}>
      <Row style={{ width: "100%"}}>
        {/* @ts-ignore*/}
        <CkIcon className="landing-commitment-item-icon" name={icon} style={{ color: "#D5000A"}} />
        <p style={{ fontSize: "20px", margin: 0, fontWeight: 600, color: "#001450", paddingLeft: "15px"}}>
          {title}
        </p>
      </Row>
      {
        !isMobile && (
          <Row style={{ paddingTop: "10px"}}>
            <ul>
              {
                listItems.map((item)=>{
                  return (
                    <li>
                      {item}
                    </li>
                  )
                })
              }
            </ul>
          </Row>
        )
      }
    </div>
  );
};

export default memo(CommitmentItem);
