import React, { FC, useRef, useEffect, useState } from "react";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { IBlogContentFragment } from "../../../Blog/interface";

const allowedIframeDomains = [
  "www.youtube.com",
  "www.vimeo.com",
  "www.soundcloud.com",
  "player.twitch.tv",
  "www.linkedin.com",
  "www.facebook.com",
  "www.twitter.com",
  "www.spotify.com",
  "www.instagram.com",
  "www.anchor.fm",
];

const allowedSchemes = ["https", "http"];

const sanitizeHTML = (htmlContent: string) => {
  const config = {
    ADD_TAGS: ["iframe"],
    FORBID_ATTR: ["style"],
    ALLOWED_TAGS: ["b", "i", "iframe"], // Add any other tags you want to allow
    ALLOWED_ATTR: ["src", "width", "height"], // Add any other attributes you want to allow
    ALLOWED_URI_REGEXP: /^(?:(?:f|ht)tps?:)?\/\//i,
    ALLOWED_SOURCES: (attr, allowedValue) =>
      allowedIframeDomains.some((domain) => attr.includes(domain)) &&
      allowedSchemes.some((scheme) => attr.startsWith(scheme)),
  };

  return DOMPurify.sanitize(htmlContent, config);
};

const adTwWidgets = (elm: HTMLElement | Element) => {
  const script = document.createElement("script");
  script.setAttribute("src", "https://platform.twitter.com/widgets.js");
  elm.appendChild(script);
};

const adIgEmbed = (elm: HTMLElement | Element) => {
  const script = document.createElement("script");
  script.setAttribute("src", "https://www.instagram.com/embed.js");
  elm.appendChild(script);
};

const fbInit = (elm: HTMLElement | Element) => {
  const script = document.createElement("script");
  script.setAttribute("src", "https://connect.facebook.net/en_US/sdk.js");
  elm.appendChild(script);
  if ("FB" in window) {
    window.FB.init({
      appId: "",
      status: true,
      cookie: true,
      xfbml: true,
      version: "v2.4",
    });
  }
};

const fbPostType = (content: string) => {
  const compareWith = content.includes("<iframe") ? "%2Fvideos%2F" : "/videos/";
  return content.includes(compareWith) === true ? "video" : "post";
};

const getRatioAndType = (fragmentContent: IBlogContentFragment) => {
  const response: {
    aspectRatio: string;
    type: "video" | "post";
  } = {
    aspectRatio: "1by1",
    type: "post",
  };
  switch (fragmentContent.platform) {
    case "youtube":
    case "vimeo":
    case "soundcloud":
    case "twich":
      response.type = "video";
      response.aspectRatio = "16by9";
      break;
    case "linkedin":
      response.type = "video";
      response.aspectRatio = "1by1";
      break;
    /* 
    case 'facebook':
      response.type = fbPostType(fragmentContent.content)
      if (response.type === 'video') {
        response.aspectRatio = '16by9'
      }
      break 
    */
    case "twitter":
      response.type = "post";
      break;
    case "spotify":
    case "instagram":
    case "anchor":
      response.type = "post";
      break;
    default:
      response.type = "post";
  }
  return response;
};

interface IProps {
  fragmentContent: IBlogContentFragment;
}

const EmbedFragment: FC<IProps> = ({ fragmentContent }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [embed, setEmbed] = useState<{
    aspectRadio: string;
    type: "video" | "post";
    source: string | JSX.Element | JSX.Element[];
  }>();

  useEffect(() => {
    if (fragmentContent) {
      const ratioAndType = getRatioAndType(fragmentContent);
      if (fragmentContent.platform === "x" && ref.current) {
        adTwWidgets(ref.current);
      }
      if (fragmentContent.platform === "instagram" && ref.current) {
        adIgEmbed(ref.current);
      }
      if (
        fragmentContent.platform === "facebook" &&
        fragmentContent.platform.includes("fb-root") &&
        ref.current
      ) {
        fbInit(ref.current);
      }
      setEmbed({
        aspectRadio: ratioAndType.aspectRatio,
        type: ratioAndType.type,
        source: parse(fragmentContent.content),
      });
    }
  }, [fragmentContent]);

  if (embed === undefined) return null;

  return (
    <div className="embed-wrapper" ref={ref}>
      <div
        className={
          embed?.type === "video"
            ? `embed-responsive embed-responsive-${embed.aspectRadio}`
            : "embed-responsive-wrapper"
        }
      >
        {embed.source}
      </div>
    </div>
  );
};

export default EmbedFragment;
