import React, { FC } from "react";
import { Image } from "antd";
import { IBlogContentFragment } from "../../../Blog/interface";

interface IProps {
  fragmentContent: IBlogContentFragment;
  sasToken: string;
}

const ImageFragment: FC<IProps> = ({ fragmentContent, sasToken }) => {
  if (fragmentContent.content === "") return null;
  return (
    <figure className="image-content">
      <Image src={`${fragmentContent.content}?${sasToken}`} alt={fragmentContent.alt} />
    </figure>
  );
};

export default ImageFragment;
