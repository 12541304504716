import React, { memo, FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  /* PinterestShareButton, */
} from "react-share";
import {
  TwiterXIcon,
  FacebookIcon,
  LinkedInIcon,
  /* PinterestIcon, */
  WhatsappIconUncoloredBlog,
} from "../../../../assets/SvgIcons";
import { IBlogEntry, IBlogTag } from "../../../../app/models/blog";

import "./style.css";

const Label: FC<{ tag: IBlogTag }> = ({ tag }) => {
  return (
    <Link className="label" to={`/blog/1?tag=${tag.urlName}`}>
      {tag.tagName}
    </Link>
  );
};

interface IProps {
  blogEntryData: IBlogEntry;
}
export const BlogFooter: FC<IProps> = memo(({ blogEntryData }) => {
  const url = window.location.href;

  const [facebook, setFacebook] = useState<{
    quote: string;
  }>();
  const [twitter, setTwiter] = useState<{
    title: string;
    hashtags: string[];
  }>();
  const [whatsapp, setWhatsapp] = useState<{
    title: string;
    separator: string;
  }>();
  const [linkedIn, setLinkedIn] = useState<{
    title: string;
    summary: string;
    image: string;
  }>();
  /* const [pinterest, setPinterest] = useState<{
    description: string;
    media: string;
  }>(); */

  useEffect(() => {
    if (!blogEntryData) return;

    const section = `Carker | Blog`;

    const facebookConfig = {
      quote: `${blogEntryData.title} | ${blogEntryData.intro}`,
    };
    setFacebook(facebookConfig);

    const twitterConfig: {
      title: string;
      hashtags: string[];
    } = {
      title: section + " | " + blogEntryData.title,
      hashtags: [],
    };
    if (Array.isArray(blogEntryData.tags) === true) {
      twitterConfig.hashtags = blogEntryData.tags.map((tag) =>
        tag.tagName.replace(/\s+/g, "")
      );
    }
    setTwiter(twitterConfig);

    const whatsappConfig = {
      title: `*${blogEntryData.title}* \n _${blogEntryData.intro}_ \n *_Carker | Blog_*`,
      separator: " \n",
    };
    setWhatsapp(whatsappConfig);

    /* const pinterestConfig = {
      description: `${blogEntryData.title} | ${blogEntryData.intro}`,
      media: blogEntryData.seoOgImage || blogEntryData.thumbnail,
    };
    setPinterest(pinterestConfig); */

    const linkedInConfig = {
      title: blogEntryData.title,
      summary: blogEntryData.intro,
      image: blogEntryData.seoOgImage || blogEntryData.thumbnail,
    };
    setLinkedIn(linkedInConfig);
  }, [blogEntryData]);

  const shareOnLinkedIn = () => {
    // Construct the LinkedIn share URL
    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url
    )}&title=${encodeURIComponent(linkedIn.title)}&summary=${encodeURIComponent(
      linkedIn.summary
    )}&source=${encodeURIComponent(linkedIn.image)}`;
    // Open a new window or redirect the current window to the LinkedIn share URL
    window.open(linkedInShareUrl, "_blank");
  };

  return (
    <div className="blog-footer">
      <div className="sub-footer">
        {blogEntryData &&
          blogEntryData.tags &&
          blogEntryData.tags.length > 0 && (
            <>
              <p className="pinned-extra">Etiquetas</p>
              <div className="blog-labels">
                {blogEntryData.tags.map((tag) => (
                  <Label tag={tag} />
                ))}
              </div>
            </>
          )}
      </div>
      <div className="blog-share">
        <div className="sub-footer">
          <p className="pinned-extra">Comparte este artículo</p>
          <div className="blog-share-buttons">
            {facebook && (
              <FacebookShareButton
                className="ant-btn ant-btn-ghost facebook"
                url={url}
                quote={facebook.quote}
              >
                <FacebookIcon />
              </FacebookShareButton>
            )}
            {twitter && (
              <TwitterShareButton
                className="ant-btn ant-btn-ghost twitter"
                url={url}
                title={twitter.title}
                hashtags={twitter.hashtags}
              >
                <TwiterXIcon />
              </TwitterShareButton>
            )}
            {linkedIn && (
              <LinkedinShareButton
                className="ant-btn ant-btn-ghost twitter"
                source={"Carker | Blog"}
                title={linkedIn.title}
                summary={linkedIn.summary}
                url={url}
              >
                <LinkedInIcon />
              </LinkedinShareButton>
            )}
            {whatsapp && (
              <WhatsappShareButton
                className="ant-btn ant-btn-ghost whatsapp"
                url={url}
                title={whatsapp.title}
                separator={whatsapp.separator}
              >
                <WhatsappIconUncoloredBlog />
              </WhatsappShareButton>
            )}
            {/* {pinterest && (
              <PinterestShareButton
                className="ant-btn ant-btn-ghost pinterest"
                url={url}
                description={pinterest.description}
                media={pinterest.media}
              >
                <PinterestIcon />
              </PinterestShareButton>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
});
