import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { message } from "antd";
import moment from "moment";

import { BlogAPI } from "../../../app/Services/BlogAPI";
import { IBlogEntry } from "../../../app/models/blog";
import { getSasToken } from "../../../stores";

import { BlogHeader } from "../Blog/BlogHeader";
import { Card } from "../Blog/Card";
import { SubscriptionForm } from "../Blog/SubscriptionForm";
import { BlogEntry } from "./BlogEntry";
import { BlogFooter } from "./BlogFooter";
import { WheelSpinner } from "../../../app/Components/Loaders/WheelSpinner";
import { CkMessage } from "../../../CkUI";
import "./styles.css";

interface IProps {}

const Blog: FC<IProps> = () => {
  /**
   * states and refs
   */
  const url = window.location.href;
  const history = useHistory();
  const sasToken = useSelector(getSasToken);
  const { urlTitle } = useParams<{ category: string; urlTitle: string }>();
  const [post, setPost] = useState<IBlogEntry>();
  const [sidePosts, setSidePosts] = useState<IBlogEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSide, setLoadingSide] = useState<boolean>(true);

  /**
   * functions
   */
  const getPost = async () => {
    setLoading(true);
    const requestPost = await BlogAPI.getPostBy({ urlTitle })
      .then((response) => response.data)
      .catch((e) => {
        console.error("error: ", e);
        return undefined;
      });
    if (requestPost === undefined) {
      CkMessage({type: "error", text: "Se produjo un error al consultar el post."});
      history.push(`/blog/1`);
      return;
    }
    if (typeof requestPost.contentFragments === "string") {
      requestPost.contentFragments = JSON.parse(requestPost.contentFragments);
    }
    setPost(requestPost);
    setLoading(false);
  };

  const getSideContents = async (id: number) => {
    setLoadingSide(true);
    const requestSidePosts = await BlogAPI.getPosts({
      page: 1,
      pageSize: 5,
      EntryIdOffset: id,
      audience: "WS",
      statusCodes: ["visible"],
      showContent: false,
    })
      .then((response) => response.data)
      .catch((e) => {
        console.error("error: ", e);
        return { entries: [] };
      });
    setSidePosts(
      requestSidePosts.entries.filter((entry) => entry.entryid !== id)
    );
    setLoadingSide(false);
  };

  /**
   * Effects
   */
  useEffect(() => {
    post && getSideContents(post.entryid);
  }, [post]);

  useEffect(() => {
    urlTitle && getPost();
  }, [urlTitle]);

  /**
   * Components
   */
  const Metatags = () => {
    if (!post) return null;

    return (
      <Helmet>
        <title>{post.title} | Blog | CarKer™</title>
        <meta
          property="og:title"
          content={post.title}
          data-react-helmet="true"
        />
        <meta
          name="description"
          content={post.seoDescription || post.intro}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={post.seoDescription || post.intro}
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content={
            post.seoKeywords || post.tags.map((tag) => tag.tagName).join(", ")
          }
          data-react-helmet="true"
        />
        <meta
          name="og:image"
          content={post.seoOgImage || post.thumbnail}
          data-react-helmet="true"
        />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="es_ES" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "${post.title}",
            "description": "${post.seoDescription || post.intro}",
            "datePublished": "${moment(post.createdDate).format("YYYY-MM-DD")}",
            "author": {
              "@type": "Person",
              "name": "${post.author.authorName}"
            },
            "image": {
              "@type": "ImageObject",
              "url": "${post.seoOgImage || post.thumbnail}"
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "${url}"
            }
          }
        `}
        </script>
      </Helmet>
    );
  };

  return (
    <>
      <Metatags />
      <div className="page-container blog-info-page">
        <WheelSpinner spinning={loading}>
          <BlogHeader />
          <div className="content-container">
            <div className="main-content">
              {post && (
                <Card
                  isLoading={loading}
                  type="big"
                  isContent
                  item={post}
                  sasToken={sasToken}
                />
              )}
              {/* <SubscriptionForm isWeb={false} /> */}

              {post && (
                <>
                  <BlogEntry blogEntryData={post} />
                  <BlogFooter blogEntryData={post} />
                </>
              )}
            </div>

            <div className="pinned-content">
              {/* <SubscriptionForm isWeb={true} /> */}

              {sidePosts && sidePosts.length > 0 && (
                <>
                  <p className="pinned-extra">Te puede interesar:</p>
                  <div className="pinned-scroll">
                    {sidePosts.map((item, index) => (
                      <Card
                        type="small"
                        key={`${index}-${item.entryid}`}
                        isLoading={loading}
                        item={item}
                        sasToken={sasToken}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </WheelSpinner>
      </div>
    </>
  );
};
export default Blog;
