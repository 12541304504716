import React, { FC } from "react";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { getSasToken } from "../../../../stores";

import TextFragment from "../Fragments/TextFragment";
import ImageFragment from "../Fragments/ImageFragment";
import VideoFragment from "../Fragments/VideoFragment";
import EmbedFragment from "../Fragments/EmbedFragment";

import { IBlogEntry, IBlogContentFragment } from "../../../../app/models/blog";

import "./style.css";

export const BlogEntry: FC<{
  blogEntryData: IBlogEntry;
}> = ({ blogEntryData }) => {
  const sasToken = useSelector(getSasToken);
  if (!blogEntryData) return null;
  return (
    <div className="post-content">
      {(blogEntryData.contentFragments as IBlogContentFragment[][]).map(
        (fragmentsRow: any, rowIndex: number) => (
          <Row gutter={10} key={`fragment-${rowIndex}`}>
            {fragmentsRow.map((fragmentCol: any, colIndex: number) => (
              <Col
                md={24}
                lg={24 / fragmentsRow.length}
                key={`col-container-${rowIndex}-${colIndex}`}
              >
                {fragmentCol.contentType === "text" && (
                  <TextFragment fragmentContent={fragmentCol} />
                )}
                {fragmentCol.contentType === "image" && (
                  <ImageFragment
                    fragmentContent={fragmentCol}
                    sasToken={sasToken}
                  />
                )}
                {fragmentCol.contentType === "video" && (
                  <VideoFragment
                    fragmentContent={fragmentCol}
                    sasToken={sasToken}
                  />
                )}
                {fragmentCol.contentType === "embed" && (
                  <EmbedFragment fragmentContent={fragmentCol} />
                )}
              </Col>
            ))}
          </Row>
        )
      )}
    </div>
  );
};
